import * as React from 'react'

import { Box, Grid, alpha, styled } from '@mui/material'

import { AppContainer, EarningsBalance, MainBalance } from 'shared/components'

import GraphWrapper from './graph_wrapper'

import type { AssetPrice } from 'shared/queries'

const padding = 15

const pixels = (i: number) => `${i}px`

const NotebookTop = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: `${pixels(2 * padding)} ${pixels(2 * padding)} 0px 0px`,
  padding: pixels(padding),
  paddingBottom: pixels(padding + 3),
  width: '84%',
  left: '8%',
  flex: 1,
  backgroundColor: theme.palette.primary.dark,
  boxShadow:
    theme.shadows[12] + `, inset 0px 1px 2px 1px ${alpha(
      theme.palette.primary.main,
      2 * theme.palette.action.activatedOpacity,
    )}`,
  '::after': {
    content: '""',
    position: 'absolute',
    height: theme.spacing(1),
    width: '13%',
    left: '50%',
    top: `calc(${theme.spacing(2)} - 2px)`,
    transform: 'translate(-50%, 0)',
    backgroundColor: theme.palette.primary.dark,
    borderRadius: `0px 0px ${pixels(padding / 2)} ${pixels(padding / 2)}`,
  },
}))

const NotebookBottom = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: `0px 0px ${pixels(2 * padding)} ${pixels(2 * padding)}`,
  height: '22px',
  width: '100%',
  backgroundColor: theme.palette.primary.dark,
  boxShadow:
    theme.shadows[12] + `, inset 0px -4px 5px 4px ${alpha(
      theme.palette.primary.main,
      theme.palette.action.activatedOpacity,
    )}`,
}))

const NotebookViewport = ({ children }: { children?: React.ReactNode }) => {
  return (
    <NotebookTop>
      <Box
        sx={{
          borderRadius: `${pixels(padding)} ${pixels(padding)} 0px 0px`,
          overflow: 'hidden',
          width: '100%',
          bgcolor: 'background.default',
          '& .MuiTypography-h4': {
            fontSize: '1.2rem',
          },
          '& .MuiTypography-h6': {
            fontSize: '.9rem',
          },
          '& .MuiSvgIcon-fontSizeMedium': {
            fontSize: '1.2rem',
          },
        }}
      >
        <Grid
          container
          sx={{
            p: 2,
            height: '100%',
            bgcolor: 'action.hover',
          }}
          spacing={2}
        >
          {children}
        </Grid>
      </Box>
    </NotebookTop>
  )
}

type NotebookDemoProps = {
  loading: boolean
  symbol: string
  usdPrices: AssetPrice[]
}

export const NotebookDemo = ({
  loading,
  symbol,
  usdPrices,
}: NotebookDemoProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <div style={{ width: '100%' }}>
      <NotebookViewport>
        <MainBalance
          loading={loading}
          currency='USD'
          amount={usdPrices[usdPrices.length - 1]?.value}
          sm={6}
          px={2}
          py={2}
        />
        <EarningsBalance
          loading={loading}
          currency='USD'
          amount={230.74}
          sm={6}
          px={2}
          py={2}
        />
        <AppContainer
          sx={{
            height: '185px',
            py: 1,
            'text': {
              fontSize: '.85em',
            },
          }}
        >
          <GraphWrapper
            symbol={symbol}
            usdPrices={usdPrices}
          />
        </AppContainer>
      </NotebookViewport>
      <NotebookBottom />
    </div>
  </Box>
)

export default NotebookDemo
