import * as React from 'react'

import { PricesGraph } from 'shared/components'
import { getPriceGraphData } from 'shared/services'

import type { AssetPrice } from 'shared/queries'

type GraphWrapperProps = {
  symbol: string
  usdPrices: AssetPrice[]
}

const GraphWrapper = ({ symbol, usdPrices }: GraphWrapperProps) => {
  const graphData = getPriceGraphData(usdPrices, symbol)

  return (
    <PricesGraph
      currency='USD'
      data={graphData.points}
      ath={graphData.ath}
      atl={graphData.atl}
    />
  )
}

export default GraphWrapper
